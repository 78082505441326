<template>
	<div>
		<confirmationModal
			title="Updating Subscription"
			message="Are you happy to proceed updating your subscription?"
			:open="confirmationModalOpenUpdate"
			ok-button="OK"
			@ok="confirm"
			@cancel="cancel"
		/>
		<confirmationModal
			title="Cancel Subscription"
			message="Are you sure you want to cancel your subscription?"
			:open="confirmationModalOpenCancel"
			ok-button="OK"
			@ok="confirm"
			@cancel="cancel"
		/>

		<div v-if="isLoading" class="w-full h-full fixed block top-0 left-0 bg-white opacity-75 z-50">
			<span class="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0" style="top: 50%">
				<font-awesome-icon :icon="['fad', 'circle-notch', 'spin']" size="5x" />
			</span>
		</div>
		<div v-else>
			<div class="bg-gray-900">
				<div class="pt-6 sm:pt-6 lg:pt-12">
					<div class="max-w-screen-xl mx-auto text-center px-4 sm:px-6 lg:px-8">
						<div class="max-w-3xl mx-auto lg:max-w-none">
							<p v-if="isTrial" class="mt-3 text-xl leading-7 bg-green-200 text-green-600 p-3">
								You are currently on a free {{ subscription.expires ? '14 day' : '' }} trial for {{ subscription.seats }} users.
								<br />
								<small>
									Invite others from the
									<router-link class="text-blue-600" :to="{ name: 'license-settings' }">License</router-link>
									Page
								</small>
							</p>
							<p v-if="subscription && subscription.expires" class="text-green-200 text-xs text-right">
								Expires {{ subscription.expires | ago }}
							</p>

							<h2 class="pt-8 text-lg leading-6 font-semibold text-gray-300 uppercase tracking-wider">
								Pricing
							</h2>
							<div v-if="subscription && !isTrial">
								<p class="mt-2 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
									Thank you for being a customer
								</p>
								<p class="mt-3 text-xl leading-7 text-gray-300">
									You can update your subscription below.
								</p>
							</div>
							<div v-else>
								<p class="mt-2 text-3xl leading-9 font-extrabold text-white sm:text-4xl sm:leading-10 lg:text-5xl lg:leading-none">
									The right price for you, whoever you are
								</p>
								<p class="mt-3 text-xl leading-7 text-gray-300">
									Select how many users below to see the cost.
								</p>
							</div>

							<div class="inline-flex mt-6 text-xl">
								<button
									:disabled="seats === 1"
									class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-l"
									@click="seatsDown"
								>
									-
								</button>

								<input
									v-model="seats"
									class="w-12 text-3xl outline-none focus:outline-none text-center bg-gray-300 font-extrabold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700 outline-none"
									type="number"
									min="1"
									name="custom-input-number"
								/>

								<button class="bg-gray-300 hover:bg-gray-400 text-gray-800 font-bold py-2 px-4 rounded-r" @click="seatsUp">+</button>
							</div>
						</div>
					</div>
				</div>
				<div class="mt-8 pb-12 bg-gray-50 sm:mt-12 sm:pb-16 lg:mt-16 lg:pb-24">
					<div class="relative">
						<div class="absolute inset-0 h-3/4 bg-gray-900"></div>
						<div class="relative z-10 max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8">
							<div class="max-w-md mx-auto lg:max-w-5xl lg:grid lg:grid-cols-2 lg:gap-5">
								<div class="rounded-lg shadow-lg overflow-hidden">
									<div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
										<div>
											<span
												class="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600"
											>
												Monthly
											</span>
										</div>
										<div class="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
											£{{ 79 * seats }}
											<span class="ml-1 text-2xl leading-8 font-medium text-gray-500">
												/mo
											</span>
										</div>
										<p v-show="seats > 1">
											£79
											<small>/month per user</small>
										</p>
									</div>
									<div class="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
										<ul>
											<li class="flex items-start">
												<div class="flex-shrink-0">
													<svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p class="ml-3 text-base leading-6 text-gray-700">
													Unlimited Models
												</p>
											</li>
											<li class="mt-4 flex items-start">
												<div class="flex-shrink-0">
													<svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p class="ml-3 text-base leading-6 text-gray-700">
													Community Access
												</p>
											</li>
											<li class="mt-4 flex items-start">
												<div class="flex-shrink-0">
													<svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p class="ml-3 text-base leading-6 text-gray-700">
													All Features
												</p>
											</li>
											<li class="mt-4 flex items-start">
												<div class="flex-shrink-0">
													<svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p class="ml-3 text-base leading-6 text-gray-700">
													Free updates
												</p>
											</li>
										</ul>
										<div class="mt-6 rounded-md shadow">
											<a
												v-if="subscription && !isCurrentSeats && isCurrentPlan(plans.monthly)"
												href="#"
												class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
												@click.prevent="updateMonthly"
											>
												Update {{ isCurrentPlan(plans.monthly) ? 'Seats of Current Plan' : 'to Monthly Plan' }}
											</a>
											<span
												v-else-if="isCurrentPlan(plans.monthly)"
												class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-900 hover:bg-indigo-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
											>
												Current Plan
											</span>
											<a
												v-else
												href="#"
												class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
												@click.prevent="billMonthly"
											>
												Get started
											</a>
										</div>
									</div>
								</div>
								<div class="mt-4 rounded-lg shadow-lg overflow-hidden lg:mt-0">
									<div class="px-6 py-8 bg-white sm:p-10 sm:pb-6">
										<div>
											<span
												class="inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-indigo-100 text-indigo-600"
											>
												Yearly
											</span>
											<span
												class="ml-5 inline-flex px-4 py-1 rounded-full text-sm leading-5 font-semibold tracking-wide uppercase bg-green-100 text-green-600"
											>
												Save £{{ Math.round(79 * seats - (790 * seats) / 12) * 12 }}
											</span>
										</div>
										<div class="mt-4 flex items-baseline text-6xl leading-none font-extrabold">
											£{{ 790 * seats }}
											<span class="ml-1 text-2xl leading-8 font-medium text-gray-500">
												/yr
											</span>
										</div>
										<p v-show="seats > 1">
											£790
											<small>/year per user</small>
										</p>
									</div>
									<div class="px-6 pt-6 pb-8 bg-gray-50 sm:p-10 sm:pt-6">
										<ul>
											<li class="flex items-start">
												<div class="flex-shrink-0">
													<svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p class="ml-3 text-base leading-6 text-gray-700">
													Unlimited Models
												</p>
											</li>
											<li class="mt-4 flex items-start">
												<div class="flex-shrink-0">
													<svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p class="ml-3 text-base leading-6 text-gray-700">
													Community Access
												</p>
											</li>
											<li class="mt-4 flex items-start">
												<div class="flex-shrink-0">
													<svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p class="ml-3 text-base leading-6 text-gray-700">
													All Features
												</p>
											</li>
											<li class="mt-4 flex items-start">
												<div class="flex-shrink-0">
													<svg class="h-6 w-6 text-green-500" stroke="currentColor" fill="none" viewBox="0 0 24 24">
														<path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M5 13l4 4L19 7" />
													</svg>
												</div>
												<p class="ml-3 text-base leading-6 text-gray-700">
													Free updates
												</p>
											</li>
										</ul>
										<div class="mt-6 rounded-md shadow">
											<a
												v-if="isTrial"
												href="#"
												class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
												@click.prevent="billYearly"
											>
												Get started
											</a>
											<a
												v-else-if="subscription && !isCurrentSeats && isCurrentPlan(plans.yearly)"
												href="#"
												class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
												@click.prevent="updateYearly"
											>
												Update {{ isCurrentPlan(plans.yearly) ? 'Seats of Current Plan' : 'to Yearly Plan' }}
											</a>
											<span
												v-else-if="isCurrentPlan(plans.yearly)"
												class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-indigo-900 hover:bg-indigo-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
											>
												Current Plan
											</span>
											<a
												v-else
												href="#"
												class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-gray-900 hover:bg-gray-800 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
												@click.prevent="billYearly"
											>
												Get started
											</a>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div class="mt-4 relative max-w-screen-xl mx-auto px-4 sm:px-6 lg:px-8 lg:mt-5">
						<div class="max-w-md mx-auto lg:max-w-5xl">
							<div class="rounded-lg bg-gray-100 px-6 py-8 sm:p-10 lg:flex lg:items-center">
								<div class="flex-1">
									<div class="mt-4 text-lg leading-7 text-gray-500">
										Have you got any questions?
									</div>
								</div>
								<div class="mt-6 rounded-md shadow lg:mt-0 lg:ml-10 lg:flex-shrink-0">
									<a
										href="mailto://sales@storism.io"
										target="blank"
										class="flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-gray-900 bg-white hover:text-gray-700 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
									>
										Contact Sales
									</a>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div v-if="subscription && !isTrial">
				<div class="bg-white shadow sm:rounded-lg mt-8">
					<div class="px-4 py-5 sm:p-6">
						<h3 class="text-lg leading-6 font-medium text-gray-900">
							Cancel Subscription
						</h3>
						<div class="mt-2 max-w-xl text-sm leading-5 text-gray-500">
							<p>
								Access will removed at the end of the current billing cycle.
							</p>
						</div>
						<div class="mt-5">
							<button type="button" class="btn-warning sm:text-sm sm:leading-5" @click="showConfirmCancel">
								Cancel Subscription
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { loadStripe } from '@stripe/stripe-js'
import confirmationModal from '@/components/shared/confirmationModal'
import firebase from '@/helpers/firebaseInit.js'
import { mapState } from 'vuex'

export default {
	name: 'Billing',
	components: {
		confirmationModal
	},
	data() {
		return {
			plans: {
				monthly: { name: 'Monthly', id: process.env.VUE_APP_STRIPE_MONTHLY_PLAN },
				yearly: { name: 'Yearly', id: process.env.VUE_APP_STRIPE_YEARLY_PLAN },
				trial: { name: 'Trial', id: 'trial' }
			},
			seats: 1,
			error: '',
			subscriptionInfo: {},
			plan: {},
			currentPlan: {},
			confirmationModalOpenUpdate: false,
			confirmationModalOpenCancel: false,
			stripeFunctions: process.env.NODE_ENV === 'production' ? 'stripe' : 'stripeTest'
		}
	},
	computed: {
		...mapState('accounts', ['current']),
		...mapState('ui', ['isLoading']),
		...mapState('auth', ['user']),
		subscription: function() {
			return this.current.subscription
		},
		isTrial: function() {
			return this.current.subscription?.plan === 'trial'
		},
		isCurrentSeats: function() {
			return this.currentPlan.seats === this.seats
		}
	},
	mounted() {
		this.plan = this.plans.yearly
		this.getSubscription()
	},
	methods: {
		isCurrentPlan: function(plan) {
			return this.currentPlan.planId === plan.id
		},
		billMonthly() {
			this.plan = this.plans.monthly
			this.bill()
		},
		billYearly() {
			this.plan = this.plans.yearly
			this.bill()
		},
		updateMonthly() {
			this.plan = this.plans.monthly
			this.showConfirmUpdate()
		},
		updateYearly() {
			this.plan = this.plans.yearly
			this.showConfirmUpdate()
		},
		async bill() {
			let stripeId = process.env.VUE_APP_STRIPE_KEY
			const stripe = await loadStripe(stripeId)

			// TODO: Move StripeTest to variable
			var createSubscriptionSession = firebase.functions.httpsCallable(`${this.stripeFunctions}-createSubscriptionSession`)

			let idProp = process.env.NODE_ENV === 'production' ? 'stripe_id' : 'stripe_id_test'

			let config = {
				stripe_id: this.$store.state.auth.user[idProp], // workaround for users in Test and not in Production
				plan: this.plan,
				seats: this.seats,
				account: this.$store.state.accounts.current.id,
				baseUrl: window.location.host
			}

			createSubscriptionSession(config).then((result) => {
				stripe.redirectToCheckout({
					sessionId: result.data.id
				})
			})
		},

		seatsDown() {
			if (this.seats > 1) {
				this.seats--
			}
		},
		seatsUp() {
			this.seats++
		},
		async cancelSubscription() {
			this.$store.commit('ui/loading', true)
			var cancelSubscription = firebase.functions.httpsCallable(`${this.stripeFunctions}-cancelSubscription`)
			cancelSubscription(this.subscription).then(() => {
				this.$store.commit('ui/loading', false)
			})
		},
		showConfirmCancel() {
			this.confirmationModalOpenCancel = true
		},
		cancelConfirmed() {
			this.cancelSubscription()
			this.confirmationModalOpenCancel = false
		},
		showConfirmUpdate() {
			this.confirmationModalOpenUpdate = true
		},
		async update() {
			this.$store.commit('ui/loading', true)
			let sub = this.subscription
			let data = {
				subscription_id: sub.subscription_id,
				customer_id: sub.customer_id,
				stripe_id: this.$store.state.auth.user.stripe_id,
				plan: this.plan,
				quantity: this.seats,
				account: this.current.id,
				baseUrl: window.location.host
			}

			var updateSubscriptionDetails = firebase.functions.httpsCallable(`${this.stripeFunctions}-updateSubscriptionDetails`)
			updateSubscriptionDetails(data).then(async () => {
				await this.getSubscription()
				this.$store.commit('ui/loading', false)
			})
		},
		async confirm() {
			this.update()
			this.confirmationModalOpenUpdate = false
		},
		async cancel() {
			this.confirmationModalOpenUpdate = false
			this.confirmationModalOpenCancel = false
		},
		async getSubscription() {
			if (!this.subscription) return

			if (this.isTrial) {
				this.subscriptionInfo = {
					seats: this.subscription.seats,
					plan: this.subscription.plan,
					planId: 'trial'
				}
				this.seats = 1 // this.subscriptionInfo.seats
				this.plan = Object.values(this.plans).find((p) => p.id === 'trial')
				this.currentPlan = this.subscriptionInfo
				return
			}

			this.$store.commit('ui/loading', true)

			var createSubscriptionSession = firebase.functions.httpsCallable(`${this.stripeFunctions}-subscriptionInfo`)

			createSubscriptionSession(this.subscription.subscription_id).then((result) => {
				this.subscriptionInfo = {
					seats: result.data.quantity,
					plan: result.data.plan.nickname,
					planId: result.data.plan.id
				}
				this.currentPlan = this.subscriptionInfo
				this.seats = result.data.quantity
				this.plan = Object.values(this.plans).find((p) => p.id === result.data.plan.id)
				this.$store.commit('ui/loading', false)
			})
		},
		async changeBilling() {
			this.$store.commit('ui/loading', true)
			let stripeId = process.env.VUE_APP_STRIPE_KEY
			const stripe = await loadStripe(stripeId)

			var createUpdateSubscriptionSession = firebase.functions.httpsCallable(`${this.stripeFunctions}-updatePayment`)
			let sub = this.$store.state.accounts.current.subscription
			let data = {
				subscription_id: sub.subscription_id,
				customer_id: sub.customer_id,
				baseUrl: window.location.host,
				account: this.$store.state.accounts.current.id
			}

			createUpdateSubscriptionSession(data).then((result) => {
				stripe
					.redirectToCheckout({
						sessionId: result.data.id
					})
					.then(function(result) {
						this.error = result.error.message
						this.$store.commit('ui/loading', false)
					})
			})
		}
	}
}
</script>
